<template>
	<div class="Brand">
		
		<div class="Top">
			<div class="Left">
				分类详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回分类列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li v-if="GoodsCategory != null && GoodsCategory.Id != 0">
					<span>分类ID</span>
					<em>
						{{GoodsCategory.Id}}
					</em>
				</li>
				
				<li>
					<span>分类名</span>
					<em  v-if="GoodsCategory != null">
						<el-input v-model="GoodsCategory.Name" style="width: 200px;"></el-input>
					</em>
				</li>
				
				<li>
					<span>短分类名</span>
					<em  v-if="GoodsCategory != null">
						<el-input v-model="GoodsCategory.ShortName" style="width: 200px;"></el-input>
					</em>
				</li>
				
				<li>
					<span>分类层级</span>
					<em v-if="GoodsCategory != null">
						<el-select @change="LevelChange" v-if="$route.params.Id == 'add'" placeholder="设置分类层级" v-model="GoodsCategory.Level">
							<el-option :label="'作为顶级分类'" :value="1"></el-option>
							<el-option :label="'作为第二层分类'" :value="2"></el-option>
							<el-option :label="'作为第三层分类'" :value="3"></el-option>
						</el-select>
						<i v-else>第{{GoodsCategory.Level}}层分类</i>
						
						<i v-if="GoodsCategory.Level < 3" style="margin-left: 40px;">
							<el-button size="mini" type="primary" @click="$Jump('/do/goods/goods_category_addchildren/'+GoodsCategory.Id)">批量建子类</el-button>
						</i>
					</em>
				</li>
				
				<li v-if="GoodsCategory != null && GoodsCategory.Level > 1">
					<span>父分类</span>
					<em>
						<!--未设置父分类时，可选择-->
						<el-select placeholder="设置父分类" v-model="GoodsCategory.FatherId" v-if="GoodsCategory.FatherId == '' || GoodsCategory.FatherName == ''">
							<el-option v-for="(item,itemI) in FatherList" :key="itemI" :label="item.Name" :value="item.Id"></el-option>
					    </el-select>
						<i v-else>{{GoodsCategory.FatherName}} (父ID{{GoodsCategory.FatherId}})</i>
					</em>
				</li>
				
				<li>
					<span>排序值（整数）</span>
					<em  v-if="GoodsCategory != null">
						<el-input v-model="GoodsCategory.Order" style="width: 100px;"></el-input>
						
					</em>
					<i><p>数字越大排名越靠前</p></i>
				</li>
				
				<li>
					<span>Icon</span>
					<em  v-if="GoodsCategory != null">
						
						<el-select placeholder="设置Icon" v-model="GoodsCategory.Icon">
							<el-option value="el-icon-ice-cream-round"><span class="el-icon-ice-cream-round"></span></el-option>
							<el-option value="el-icon-ice-cream-square"><span class="el-icon-ice-cream-square"></span></el-option>
							<el-option value="el-icon-lollipop"><span class="el-icon-lollipop"></span></el-option>
							<el-option value="el-icon-potato-strips"><span class="el-icon-potato-strips"></span></el-option>
							<el-option value="el-icon-milk-tea"><span class="el-icon-milk-tea"></span></el-option>
							<el-option value="el-icon-ice-drink"><span class="el-icon-ice-drink"></span></el-option>
							<el-option value="el-icon-ice-tea"><span class="el-icon-ice-tea"></span></el-option>
							<el-option value="el-icon-coffee"><span class="el-icon-coffee"></span></el-option>
							<el-option value="el-icon-orange"><span class="el-icon-orange"></span></el-option>
							<el-option value="el-icon-pear"><span class="el-icon-pear"></span></el-option>
							<el-option value="el-icon-apple"><span class="el-icon-apple"></span></el-option>
							<el-option value="el-icon-cherry"><span class="el-icon-cherry"></span></el-option>
							<el-option value="el-icon-watermelon"><span class="el-icon-watermelon"></span></el-option>
							<el-option value="el-icon-grape"><span class="el-icon-grape"></span></el-option>
							<el-option value="el-icon-refrigerator"><span class="el-icon-refrigerator"></span></el-option>
							<el-option value="el-icon-goblet-square-full"><span class="el-icon-goblet-square-full"></span></el-option>
							<el-option value="el-icon-goblet-square"><span class="el-icon-goblet-square"></span></el-option>
							<el-option value="el-icon-goblet-full"><span class="el-icon-goblet-full"></span></el-option>
							<el-option value="el-icon-goblet"><span class="el-icon-goblet"></span></el-option>
							<el-option value="el-icon-cold-drink"><span class="el-icon-cold-drink"></span></el-option>
							<el-option value="el-icon-coffee-cup"><span class="el-icon-coffee-cup"></span></el-option>
							<el-option value="el-icon-water-cup"><span class="el-icon-water-cup"></span></el-option>
							<el-option value="el-icon-hot-water"><span class="el-icon-hot-water"></span></el-option>
							<el-option value="el-icon-ice-cream"><span class="el-icon-ice-cream"></span></el-option>
							<el-option value="el-icon-dessert"><span class="el-icon-dessert"></span></el-option>
							<el-option value="el-icon-sugar"><span class="el-icon-sugar"></span></el-option>
							<el-option value="el-icon-tableware"><span class="el-icon-tableware"></span></el-option>
							<el-option value="el-icon-burger"><span class="el-icon-burger"></span></el-option>
							<el-option value="el-icon-knife-fork"><span class="el-icon-knife-fork"></span></el-option>
							<el-option value="el-icon-fork-spoon"><span class="el-icon-fork-spoon"></span></el-option>
							<el-option value="el-icon-chicken"><span class="el-icon-chicken"></span></el-option>
							<el-option value="el-icon-food"><span class="el-icon-food"></span></el-option>
							<el-option value="el-icon-dish-1"><span class="el-icon-dish-1"></span></el-option>
							<el-option value="el-icon-dish"><span class="el-icon-dish"></span></el-option>
							<el-option value="el-icon-moon-night"><span class="el-icon-moon-night"></span></el-option>
							<el-option value="el-icon-moon"><span class="el-icon-moon"></span></el-option>
							<el-option value="el-icon-cloudy-and-sunny"><span class="el-icon-cloudy-and-sunny"></span></el-option>
							<el-option value="el-icon-partly-cloudy"><span class="el-icon-partly-cloudy"></span></el-option>
							<el-option value="el-icon-cloudy"><span class="el-icon-cloudy"></span></el-option>
							<el-option value="el-icon-sunny"><span class="el-icon-sunny"></span></el-option>
							<el-option value="el-icon-sunset"><span class="el-icon-sunset"></span></el-option>
							<el-option value="el-icon-heavy-rain"><span class="el-icon-heavy-rain"></span></el-option>
							<el-option value="el-icon-lightning"><span class="el-icon-lightning"></span></el-option>
							<el-option value="el-icon-wind-power"><span class="el-icon-wind-power"></span></el-option>
							<el-option value="el-icon-mobile-phone"><span class="el-icon-mobile-phone"></span></el-option>
							<el-option value="el-icon-truck"><span class="el-icon-truck"></span></el-option>
							<el-option value="el-icon-watch"><span class="el-icon-watch"></span></el-option>
							<el-option value="el-icon-service"><span class="el-icon-service"></span></el-option>
							<el-option value="el-icon-mobile-phone"><span class="el-icon-mobile-phone"></span></el-option>
							<el-option value="el-icon-bicycle"><span class="el-icon-bicycle"></span></el-option>
						</el-select>
						
						<i :class="GoodsCategory.Icon" style="font-size: 1.3rem;padding-left: 10px;"></i>
						
					</em>
				</li>
				

				<li>
					<span>分类状态</span>
					<em  v-if="GoodsCategory != null">
						<el-select placeholder="设置账号状态" v-model="GoodsCategory.Status">
							<el-option label="删除" :value="0" disabled></el-option>
							<el-option label="正常" :value="50"></el-option>
						    <el-option label="禁用" :value="10"></el-option>
					    </el-select>
					</em>
				</li>
				
			
				<li v-if="GoodsCategory != null && GoodsCategory.Id != 0">
					<span>创建时间</span>
					<em>
						{{GoodsCategory.CreatedAt}}
					</em>
				</li>
				
				<li v-if="GoodsCategory != null && GoodsCategory.Id != 0">
					<span>最后修改时间</span>
					<em>
						{{GoodsCategory.UpdatedAt}}
					</em>
				</li>
				
				<li>
					<span></span>
					<em>
						<el-button v-if="GoodsCategory != null && GoodsCategory.Id != ''" @click="UpdateGoodsCategory()" type="danger">提交修改</el-button>
						<el-button v-else-if="GoodsCategory != null && GoodsCategory.Id == ''" @click="UpdateGoodsCategory()" type="danger">添加分类</el-button>
					</em>
				</li>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload} from 'element-ui'
	export default {
	  name: 'GoodsCategoryInfo',
	  props: {
	  },
	  data() {
	      return {
			  GoodsCategory:null,
			  FatherList:[],
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
	  },
	  created() {
		this.GetFatherList()
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少分类ID，页面无法工作')
			return
		}
		if(this.$route.params.Id == 'add'){
			this.InitGoodsCategory()
			return
		}
		this.GetGoodsCategory(this.$route.params.Id)
	  },
	  methods:{
		  getFile(file, fileList) {
				this.getBase64(file.raw).then(res => {
					this.uploadPic(res)
			    });
		  },
		  getBase64(file) {
		        return new Promise(function(resolve, reject) {
		          let reader = new FileReader();
		          let imgResult = "";
		          reader.readAsDataURL(file);
		          reader.onload = function() {
		            imgResult = reader.result;
		          };
		          reader.onerror = function(error) {
		            reject(error);
		          };
		          reader.onloadend = function() {
		            resolve(imgResult);
		          };
		        });
		      },
			  uploadPic(_b64){
				  let that = this
				  let data = {
				  	Service:'Goods',
				  	Class: 'File',
				  	Action: 'Base64Upload',
				  	Base64:_b64
				  }
				  this.$post(that.$store.getters.getApiHost,data)
				  .then((res) => {
				  	
				  	if(res.ErrorId != 0){
				  		that.$message(res.Msg)
				  		return
				  	}
				  	
				  	that.GoodsCategory.Icon = res.Data.Url
				  	
				  })
				  .catch(function (response) {
				  	that.DialogMsg = '网络请求错误'
				  })
			  },
		  InitGoodsCategory(){
			this.GoodsCategory = {
				Id:'',
				Name:'',
				ShortName:'',
				Status:50,
				FatherId:'',
				Icon:'',
				SnCatId:'',
				JdCatId:'',
				Level:1,
				Order:0,
			}
		  },
		  LevelChange(_selectVal){
			  if(_selectVal <= 1){
				  return
			  }
			  if(this.$route.params.Id != 'add'){
				  return
			  }
			  this.GetFatherList(1)
		  },
		  GetFatherList(_page){
			
		  		let that = this
				
				if(that.GoodsCategory == null || that.GoodsCategory.Level <= 1){
					return
				}
				
		  		let data = {
		  			Service:'Goods',
		  			Class: 'GoodsCategory',
		  			Action: 'List',
		  			Page:1,
		  			PageSize:500,
					Level:that.GoodsCategory.Level-1
		  		}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				that.$message(res.Msg)
		  				return
		  			}
		  			
					that.FatherList = res.Data.CategoryList
		  			
		  		})
		  		.catch(function (response) {
		  			that.DialogMsg = '网络请求错误'
		  		})
		  },
		    GetGoodsCategory(_id){
		  		let that = this
		  		let data = {Service:'Goods',Class: 'GoodsCategory',Action: 'Get',Id:_id,}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				that.$message(res.Msg)
		  				return
		  			}
		  			that.GoodsCategory = res.Data
		  			
		  		})
		    },
			UpdateGoodsCategory(){
				let that = this
				let data = {Service:'Goods',Class: 'GoodsCategory',Action: 'Update',Id:that.GoodsCategory.Id,Status:that.GoodsCategory.Status,Name:that.GoodsCategory.Name,ShortName:that.GoodsCategory.ShortName,Icon:that.GoodsCategory.Icon,Level:that.GoodsCategory.Level,FatherId:that.GoodsCategory.FatherId,Order:that.GoodsCategory.Order}
				if(that.GoodsCategory.Id == 0){
					data.Action = 'Add'
					data.Id = 0
				}
				this.$post(that.$store.getters.getApiHost,data)
				.then((res) => {
					that.$message(res.Msg)
					if(res.ErrorId == 0){
						that.$Jump('/do/goods/goods_category_list')
					}
				})
			},
	  }
	}
</script>

<style scoped>
.Brand{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Brand .Top{
	display: flex;
	align-items: center;
}
.Brand .Top .Left{
	
}
.Brand .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Brand .List{
	margin-top: 20px;	
}
.Brand .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Brand .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Brand .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.Brand .List .One li  em{
	flex: 1;
}
.Brand .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}


.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 64px;
    height: 64;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }

</style>
